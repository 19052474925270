import PropTypes from "prop-types";
import { useState } from "react";
import { Link } from "react-router-dom";
const Listcontacts = ({ contacts, onDeletecontact }) => {
  const [query, setquery] = useState("");
  const updatequery = (query) => {
    setquery(query.trim());
  };
  const clearquery = () => {
    setquery("");
  };
  const showingcontacts =
    query === ""
      ? contacts
      : contacts.filter((c) =>
          c.name.toLowerCase().includes(query.toLowerCase())
        );
  return (
    <div className="list-contacts">
      <div className="list-contacts-top">
        <input
          className="search-contacts"
          type="text"
          placeholder="Search Contacts"
          value={query}
          onChange={(event) => updatequery(event.target.value)}
        />
        <Link to="/create" className="add-contact">
          Add Contact
        </Link>
      </div>
      {showingcontacts.length !== contacts.length && (
        <div className="showing-contacts">
          <span>
            Now Showing {showingcontacts.length} of {contacts.length}
          </span>
          <button onClick={clearquery}>Show All</button>
        </div>
      )}
      <ol className="contact-list">
        {showingcontacts.map((contact) => (
          <li key={contact.id} className="contact-list-item">
            <div
              className="contact-avatar"
              style={{
                backgroundImage: `url(${contact.avatarURL})`,
              }}
            ></div>
            <div className="contact-details">
              <p>{contact.name}</p>
              <p>{contact.handle}</p>
            </div>
            <button
              className="contact-remove"
              onClick={() => onDeletecontact(contact)}
            >
              Remove
            </button>
          </li>
        ))}
      </ol>
    </div>
  );
};

Listcontacts.propTypes = {
  contacts: PropTypes.array.isRequired,
  onDeletecontact: PropTypes.func.isRequired,
};
export default Listcontacts;
