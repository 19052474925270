import "../css/App.css";
import Listcontacts from "./ListContacts";
import { useState, useEffect } from "react";
import * as contactapi from "../utils/ContactsAPI";
import Createcontact from "./createcontact";
import { Routes, Route, useNavigate } from "react-router-dom";

const App = () => {
  let navigate = useNavigate();
  const [contacts, setcontacts] = useState([]);
  const removecontact = (contact) => {
    setcontacts(contacts.filter((c) => c.id !== contact.id));
    contactapi.remove(contact);
  };
  const oncreatecontact = (contact) => {
    const create = async () => {
      const res = await contactapi.create(contact);
      console.log(contact);
      setcontacts(contacts.concat(res));
    };
    create();
    navigate("/");
  };

  useEffect(() => {
    const getcontacts = async () => {
      const res = await contactapi.getAll();

      setcontacts(res);
    };
    getcontacts();
  }, []);

  return (
    <Routes>
      <Route
        exact
        path="/"
        element={
          <Listcontacts contacts={contacts} onDeletecontact={removecontact} />
        }
      />
      <Route
        path="/create"
        element={
          <Createcontact
            oncreatecontact={(contact) => {
              oncreatecontact(contact);
            }}
          />
        }
      />
    </Routes>
  );
};

export default App;
